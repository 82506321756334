import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import services from "../services";

export default () => {
	const location = useLocation();

	const [isActive, setIsActive] = useState(false);
	const [isTitleActive, setIsTitleActive] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	useEffect(() => { checkLoginScreen() }, [location]);

	const checkLoginScreen = () => {
		if (isLoggedIn !== true) {
			setIsLoggedIn(services.auth.isGoogleUserLoggedIn())
		}
	};

	const toggleNav = () => {
		setIsActive(!isActive);
	};

	// toggleDropdown = () => {
	// 	this.setState((prevState) => ({
	// 		isDropdownActive: !prevState.isDropdownActive,
	// 	}));
	// };

	const onLogoutClick = async () => {
		if (await services.auth.logoutGoogle()) {
			this.props.history.replace("/googleLogin");
			await this.setState({ isLoggedIn: false });
		}
	};

	const colorizeTabs = (path) => {
		if (window.location.pathname === path) {
			return "is-primary-button no-underline navlink-default-text";
		}
		return "is-primary2-button no-underline navlink-default-text";
	}

	const renderTabs = () => {
		return (
			<div
				className={
					isActive ? "navbar-menu is-active" : "navbar-menu"
				}
			>
				<div className="navbar-end">
					<div className="navbar-item">
						<NavLink
							onClick={() => { }}
							to="/schedule"
							className={() => colorizeTabs('/schedule')}
						>
							<div onClick={() => { }}>
								Schedule <i className="fas fa-calendar" />
							</div>
						</NavLink>
					</div>
					<div className="navbar-item">
						<NavLink
							onClick={() => { }}
							to="/profile"
							className={() => colorizeTabs('/profile')}
						>
							<div onClick={() => { }}>
								Profile <i className="fas fa-user" />
							</div>
						</NavLink>
					</div>
					<div className="navbar-item">
						<NavLink onClick={() => { }} to="/"
							className={() => colorizeTabs('/logout')}
						>
							<div onClick={() => onLogoutClick()}>
								Logout <i className="fas fa-sign-out-alt" />
							</div>
						</NavLink>
					</div>
				</div>
			</div>
		);
	};


	return (
		<div
			style={{
				visibility: services.auth.isGoogleUserLoggedIn() ? "visible" : "hidden",
			}}
		>
			<nav
				className={
					services.auth.isGoogleUserLoggedIn() ? "navbar" : "navbar is-hidden"
				}
				role="navigation"
				aria-label="main navigation"
			>
				<div className="navbar-brand">
					<a className="navbar-item no-decoration" href="/">
						{/* <img src={require('images....')} style={{ maxHeight: "none" }} width="200" height="200" /> */}
						<h1 className={`${isTitleActive ? "subtitle is-primary-txt no-underline has-text-weight-bold"
							: "subtitle is-primary2-txt no-underline has-text-weight-bold"}`} >PrinciplesReiGroup Web Tool</h1>
					</a>
					<button
						className={
							isActive
								? "nav-hamburger button navbar-burger is-active"
								: "nav-hamburger button navbar-burger"
						}
						onClick={toggleNav}
						data-target="navbarContent"
					>
						<span style={{ background: "white" }}></span>
						<span style={{ background: "white" }}></span>
						<span style={{ background: "white" }}></span>
					</button>
				</div>
				{renderTabs()}
			</nav>
			<hr className="header-hr" />
		</div>
	);
}
