import React, { useEffect, useState, useCallback, useRef } from "react";
import CsvDownload from 'react-json-to-csv';
import { Calendar, momentLocalizer } from "react-big-calendar";
import RotateLoader from "react-spinners/RotateLoader";
import styles from "../../styles";
import moment from 'moment';
import services from "../../services";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal);
const DATE_NOW = new Date();

const localizer = momentLocalizer(moment);
const getFormattedDateValue = (value) => {
    if (value.toString().length < 2) {
        return `0${value}`;
    }
    return value;
}
const getDateMonth = (DATE_TODAY = new Date()) => { return getFormattedDateValue((DATE_TODAY.getUTCMonth() + 1)) }

export default () => {
    const calRef = useRef(null);
    const [isFirstPageLoad, setIsFirstPageLoad] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [smsEvents, setSmsEvents] = useState([]);
    const [smsEventsRawData, setSmsEventsRawData] = useState([]);
    const [currentMonth, setCurrentMonth] = useState(DATE_NOW.getUTCMonth() + 1);

    // TODO: remove?
    // const onViewChange = useCallback(async (newView) => {
    //     console.log(newView);
    //     console.log(newView.start)
    //     if (newView == null || newView.start == null || newView.end == null) {
    //         return;
    //     }
    //     const monthDate = new Date((newView.start.getTime() + newView.end.getTime()) / 2);
    //     const monthChange = monthDate.getUTCMonth() + 1;
    //     console.log(monthChange)
    //     if (monthChange == currentMonth) {
    //         return;
    //     }
    //     setCurrentMonth(monthChange);
    //     setSmsEvents(await loadMonthData(monthChange));
    // });

    const onNavigate = useCallback(async (dateString) => {
        if (dateString == null) {
            return;
        }
        const monthDate = new Date(dateString);
        const monthChange = monthDate.getUTCMonth() + 1;
        console.log(monthChange)
        if (monthChange == currentMonth) {
            return;
        }
        setCurrentMonth(monthChange);
        setSmsEvents(await loadMonthData(monthChange));
    });

    const onSelectEvent = (event) => {
        // console.log(event)
        MySwal.fire({
            title: <p>Message Details</p>,
            html: <div>
                <p><b>Created By:</b> {event.createdBy}</p>
                <p><b>To Phone:</b> {event.toPhone}</p>
                <p><b>Text:</b> {event.fullMessage}</p>
            </div>,
            // footer: 'Copyright 2018',
            confirmButtonColor: 'Black',
        });
    }

    // manage api rendering on the fly
    useEffect(() => {
        let active = true;
        load();
        return () => {
            active = false;
        };

        async function load() {
            if (!isFirstPageLoad) {
                return;
            }
            setIsFirstPageLoad(false);
            setSmsEvents(await loadMonthData(currentMonth));
            if (!active) {
                return;
            }
        }
    }, [smsEvents.length]);


    const loadMonthData = async (month) => {
        setIsLoading(true);
        const resultSmsList = [];
        const loadResults = await services.api.getScheduledSmsEvents(getDateMonth(new Date(`${DATE_NOW.getUTCFullYear()}/${month}`)));
        if (loadResults == null || loadResults.Items == null || loadResults.Items.length < 0) {
            return resultSmsList;
        }
        setIsLoading(false);
        setSmsEventsRawData(loadResults.Items);
        for (let item of loadResults.Items) {
            resultSmsList.push({
                id: item.id,
                title: item.created_by_name + " message: " + item.text.substring(0, 20) || 'Title place holder',
                fullMessage: item.text,
                createdBy: item.created_by_name,
                toPhone: item.remote_phone,
                allDay: false,
                start: new Date(item.date_scheduled),
                end: new Date(item.date_scheduled)
            });
        }
        return resultSmsList;
    }

    return (
        <div className="container is-fluid is-margin-top">
            <RotateLoader
                css={styles.override}
                size={30}
                margin={30}
                color={"#e6282c"}
                loading={isLoading}
            />
            <div style={{ marginBottom: '10px', width: "100%", textAlign: "right" }}>
                <CsvDownload data={smsEventsRawData}>Download Data To Csv</CsvDownload>
            </div>
            <Calendar
                ref={calRef}
                // onView={onView}
                // onRangeChange={onViewChange}
                onNavigate={onNavigate}
                onSelectEvent={onSelectEvent}
                localizer={localizer}
                events={smsEvents}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
            />
        </div>);
}