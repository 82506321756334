import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
// auth service
import services from "../../services";
// refresh token
import { refreshTokenSetup } from '../../services/utils/refreshToken';

function LoginHooks() {
    const navigate = useNavigate();

    const onSuccess = async (res) => {
        console.log('Login Success: currentUser:', res.profileObj);
        // alert(
        //     `Logged in successfully welcome ${res.profileObj.name} 😍. \n See console for full profile object.`
        // );
        refreshTokenSetup(res);
        console.log(res.tokenObj)
        await services.auth.loginGoogle(res.tokenObj);
        navigate("/", { replace: true });
    };

    const onFailure = (res) => {
        console.log('Login failed: res:', res);
        alert(
            `Failed to login. 😢 Please ping kevin.`
        );
    };

    // const { signIn } = useGoogleLogin({
    //     onSuccess,
    //     onFailure,
    //     // i.e. '707788443358-u05p46nssla3l8tmn58tpo9r5sommgks.apps.googleusercontent.com'
    //     clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    //     isSignedIn: true,
    //     accessType: 'offline',
    //     // responseType: 'code',
    //     // prompt: 'consent',
    // });

    const backwardsCompatToken = (credentialResponse) => {
        const token = jwt_decode(credentialResponse.credential);

        const id_token = credentialResponse.credential;
        return {
            id_token: id_token,
            profileObj: token,
            tokenObj: {
                ...token,
                expires_in: token.exp,
                id_token: id_token
            },
            reloadAuthResponse: () => {
                // TODO: if token expired then return route back to here to relogin? 
                // or try to automatic relogin 
                // (I dont think this was correct before where it was called, so ingvestiate, 
                // ideally, once expired you have to relogin which is what was happening anyway)
                return {
                    id_token: id_token,
                    profileObj: token,
                    tokenObj: {
                        ...token,
                        expires_in: token.exp,
                        id_token: id_token
                    }
                }
            }
        }
    }

    return (
        <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
            {/* <button onClick={signIn} className="button">
                <img src="icons/google.svg" alt="google login" className="icon"></img>
                <span className="buttonText">Sign in with Google</span>
            </button> */}
            <GoogleLogin
                onSuccess={credentialResponse => {
                    console.log(credentialResponse);
                    console.log(jwt_decode(credentialResponse.credential))
                    onSuccess(backwardsCompatToken(credentialResponse));
                }}
                onError={(res) => {
                    console.log('Login Failed');
                    onFailure(res);
                }}
                useOneTap
            />;
        </GoogleOAuthProvider>

    );
}
export default LoginHooks;