import jwt_decode from "jwt-decode";
import api from "./api";

const login = async (username, password) => {
	let accessToken = await api.login(username, password);
	await localStorage.setItem("AccessToken", accessToken);
	return true;
};
const logout = async () => {
	let token = getToken();
	await api.logout(token);
	await localStorage.setItem("AccessToken", null);
	return true;
};
const getToken = () => {
	const token = localStorage.getItem("AccessToken");
	if (token == null || token === "null" || token === "") {
		return null;
	}
	return token;
};
const isUserLoggedIn = () => {
	if (getToken() == null) {
		return false;
	}
	const decodedToken = jwt_decode(getToken());
	// console.log("DECODED TOKEN", decodedToken);
	// console.log("TOKEN STILL VALID TIME", Date.now() >= decodedToken.exp * 1000);
	if (Date.now() >= decodedToken.exp * 1000) {
		return false;
	}
	return true;
};

/********* GOOGLE AUTHENTICATION BREAKOUT  *********/
const getGoogleToken = () => {
	const tokenObj = localStorage.getItem("GoogleAccessToken");
	if (tokenObj == null || tokenObj === "null" || tokenObj === "") {
		return null;
	}
	return JSON.parse(tokenObj);
};
const getGoogleAuthToken = () => {
	try {
		if (getGoogleToken() == null) {
			return null;
		}
		return getGoogleToken().id_token;
	} catch (e) {
		return null
	}
}
const isGoogleUserLoggedIn = () => {
	try {
		if (getGoogleToken() == null) {
			return false;
		}
		const decodedToken = jwt_decode(getGoogleAuthToken());
		// console.log("DECODED TOKEN", decodedToken);
		// console.log("TOKEN STILL VALID TIME", Date.now() >= decodedToken.exp * 1000);
		if (Date.now() >= decodedToken.exp * 1000) {
			return false;
		}
		return true;
	} catch (e) {
		console.log(e);
	}
	return false;
};
const loginGoogle = async (tokenObj) => {
	await localStorage.setItem("GoogleAccessToken", JSON.stringify(tokenObj));
	return true;
};
const logoutGoogle = async () => {
	await localStorage.setItem("GoogleAccessToken", null);
	return true;
}
export default {
	login,
	logout,
	getToken,
	isUserLoggedIn,
	getGoogleToken,
	getGoogleAuthToken,
	isGoogleUserLoggedIn,
	loginGoogle,
	logoutGoogle
};
