import React, { createRef, useEffect } from "react";
import { SmartViewConfigForm } from "./SmartViewConfigForm";
import { Modal } from "../../../components/Modal";

export const SmartViewConfigEdit = ({
    onSubmit,
    onCancel,
    selectSmartViewResult,
    smartViewConfig,
    existingSmartViewConfigs,
    templates,
    userProfiles,
    open,
    isLoading,
}) => {
    const data = createRef();

    useEffect(() => {
        data.current = smartViewConfig;
    }, [smartViewConfig, data]);

    const handleModalAction = (action) => {
        switch (action) {
            case "submit":
                onSubmit(data.current);
                break;
            case "cancel":
            default:
                onCancel();
        }
    };

    const handleOnChange = (formData) => {
        data.current = formData;
    };

    console.log("SMART VIEW CONFIG PASSED TO EDIT:", smartViewConfig);
    const isUpdateMode = smartViewConfig != null;
    return (
        open && (
            <Modal
                title={isUpdateMode ? "Edit Smart View Config" : "New Smart View Config"}
                onClose={() => handleModalAction("cancel")}
                isVisible={open}
                onAction={handleModalAction}
                actionsDisabled={isLoading}
            >
                <SmartViewConfigForm
                    onChange={handleOnChange}
                    smartViewConfig={smartViewConfig}
                    existingSmartViewConfigs={existingSmartViewConfigs}
                    smartViewId={selectSmartViewResult.item.id}
                    templates={templates}
                    userProfiles={userProfiles}
                />
            </Modal>
        )
    );
};
