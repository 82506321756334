import React, { useState, useEffect, useLayoutEffect } from "react";
import startCase from "lodash.startcase";
import { voidFn } from "../../../../utils/voidFn";
import { identityFn } from "../../../../utils/identityFn";
const DAYS_OF_WEEK = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

export const InputFormMultiButtonSelect = ({
    name,
    formData,
    onChange = () => voidFn,
    customLabel = undefined,
    formatFunction = identityFn,
}) => {
    const label = customLabel || startCase(name);

    // TODO: break this out into own component
    const renderButtons = () => {
        const buttons = [];
        for (let day of DAYS_OF_WEEK) {
            buttons.push(<a key={day} className={`button ${formData[name]?.value.includes(day) ? "is-primary2-button" : "is-primary"}`}
                id={day} onClick={buttonClick}>{day}</a>)
        }
        return buttons;
    }

    const buttonClick = (event) => {
        let newArray = formData[name].value ? formData[name]?.value.split(',') : [];
        if (!newArray.includes(event.target.id)) {
            newArray.push(event.target.id);
        } else {
            newArray = newArray.filter((item) => {
                return item !== event.target.id;
            });
        }
        handleChange(newArray);
    }

    const handleChange = (selectedDays) => {
        onChange(name)(formatFunction(selectedDays.toString()));
    };

    return (
        <div className="field is-full-width">
            <label className="is-size-6 has-text-grey">
                {label}:
            </label>
            <div className="buttons is-center">
                {renderButtons()}
            </div>
        </div >
    );
};
