import React from "react";
import startCase from "lodash.startcase";
import { voidFn } from "../../../../utils/voidFn";
import { identityFn } from "../../../../utils/identityFn";

export const InputFormDropDownField = ({
    name,
    formData,
    selectOptions = [],
    onChange = () => voidFn,
    customLabel = undefined,
    formatFunction = identityFn,
}) => {
    const label = customLabel || startCase(name);

    const renderOptions = () => {
        const options = [];

        if (!formData[name].value) {
            options.push(<option key={"selectfirstitem"} value={null}>Select Template Association</option>);
        } else {
            options.push(<option selected key={formData[name].value} value={formData[name].value}>{
                selectOptions.find(item => item.id === formData[name].value).templateName}</option>);
            selectOptions = selectOptions.filter(item => item.id !== formData[name].value);
        }
        for (let item of selectOptions) {
            options.push(<option key={item.id} value={`${item.id}`}>{item.templateName}</option>)
        }
        return options;
    }

    const handleChange = (event) => {
        onChange(name)(formatFunction(event.target.value));
    };

    return (
        <div className="field is-full-width">
            <label className="is-size-6 has-text-grey">
                {label}:
            </label>
            <div className="control">
                <div className="select is-primary"
                    onChange={handleChange}
                >
                    <select>
                        {renderOptions()}
                    </select>
                </div>
            </div>
        </div>
    );
};
