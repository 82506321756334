import React from "react";

const Footer = () => (
	<footer
		className="footer"
		style={{
			color: "#e6282c",
			alignContent: "center",
			alignItems: "center",
			alignSelf: "center",
			background: "white",
			// background: "#AFDBD8"
		}}
	>
		<div className="container">
			<div className="content has-text-centered">
				<p>
					{" "}
					Powered By <strong className="is-primary-txt">Ucept LLC</strong> <br />
					Copyright {new Date().getFullYear()} <br />
				</p>
			</div>
		</div>
	</footer>
);

export default Footer;
