import { Searchbar } from "../../../components/Searchbar";
import React from "react";

export const SmartViewsToolbar = ({
    onSearch,
    onNew
}) => {

    return (
        <div className="is-flex is-flex-direction-row is-align-items-center">
            <div className="is-flex-grow-5">
                <Searchbar onSearch={onSearch} />
            </div>
            {onNew != null ?? <div className="ml-1">
                <button className="button is-primary2-button" onClick={onNew}>
                    <span className="icon">
                        <i className="fas fa-network-wired" />
                    </span>
                    <span>New</span>
                </button>
            </div>
            }
        </div>
    );
};
