import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import RotateLoader from "react-spinners/RotateLoader";
import { useMounted } from "../../hooks/useMounted";
import styles from "../../styles";
import services from "../../services";
import { JsonTree } from "./components/JsonTree";
import { toast } from "react-toastify";
import DataTable from './components/DataTable';
import { SmartViewsTable } from './components/SmartViewsTable';
import { SmartViewConfigEdit } from './components/SmartViewConfigEdit';
import { tryRunAsyncFactory } from "../../utils/tryRunAsyncFactory";
import { SchedulerToolbar } from './components/SchedulerToolbar';
import { SelectSmartView } from './components/SelectSmartView';
import { IconButton } from "../../components/IconButton";

const VSpacer = () => <div className="mt-6" />;

export default () => {
	const navigate = useNavigate();
	const isMounted = useMounted();

	const [isLoading, setIsLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [smartViewResults, setSmartViewResults] = useState([]);
	const [selectSmartViewResult, setSelectSmartViewResult] = useState([]);
	const [handlingState, setHandlingState] = useState(null);
	const [templates, setTemplates] = useState([]);
	const [userProfiles, setUserProfiles] = useState([]);

	const tryRunAsync = useMemo(() => tryRunAsyncFactory(setIsLoading), []);

	// check google logged in for this view
	useEffect(() => {
		if (!services.auth.isGoogleUserLoggedIn()) {
			navigate("/googleLogin", { replace: true });
		}
	})

	// manage api rendering on the fly
	useEffect(() => {
		let active = true;
		load();
		return () => {
			active = false;
		};

		async function load() {
			setIsLoading(true);
			const [smartViewResults, templates, userProfiles] = await Promise.all([
				services.api.getSmartViews(),
				services.api.getAllTemplates(),
				services.api.getUserProfiles()
			]);
			setSmartViewResults(smartViewResults);
			setTemplates(templates);
			setUserProfiles(userProfiles);
			setIsLoading(false);
			if (!active) {
				return;
			}
		}
	}, [smartViewResults.length]);

	const tableData = useMemo(() => {
		if (!smartViewResults) return [];

		const header = [
			"Action",
			// "Id",
			"Name",
			"Is Shared",
			"Type",
			"Query"
		];

		const IconsButton = (item) => {
			return (<div>
				{!item.item.smartViewConfig && <IconButton
					onClick={() => handleOnNew(item)}
					title="Add"
					iconName="fa-plus"
					classOptions='is-primary2-color-icon  is-small'
				/>}
				{item.item.smartViewConfig != null && <IconButton
					onClick={() => handleOnEdit(item)}
					title="Edit"
					iconName="fa-edit"
					classOptions='is-primary2-color-icon is-small'
				/>}
				{item.item.smartViewConfig != null && <IconButton
					onClick={() => handleOnDelete(item)}
					title="Delete"
					iconName="fa-trash"
					classOptions='is-primary2-color-icon is-small is-margin-left'
				/>}
			</div>);
		}

		const rows = smartViewResults.map((r) => [
			(<IconsButton item={r} />),
			// r.id,
			r.name,
			r.is_shared.toString(),
			r.type,
			r.query
		]);
		return { header, rows };
	}, [smartViewResults]);

	const fetchAllSmartViewConfigs = useCallback(async () => {
		await tryRunAsync(async () => {
			const data = await services.api.getSmartViews();
			isMounted && setSmartViewResults(data);
		});
	}, [isMounted, tryRunAsync]);

	const createOrUpdateSmartViewConfig = useCallback(
		async (data, isDelete) => {
			if (data == null) {
				toast.error("FILL OUT DATA");
				return
			}
			await tryRunAsync(async () => {
				if (handlingState === 'new' && !isDelete) {
					await services.api.createSmartViewConfig(data);
					toast.success("Successfully created Network");
				}
				if (handlingState === 'edit' && !isDelete) {
					await services.api.updateSmartViewConfig(data);
					toast.success("Successfully updated Network");
				}
				if (isDelete) {
					await services.api.deleteSmartViewConfig(data.item.id);
					toast.success("Successfully delete Network");
				}
				if (!isMounted) return;
				setModalOpen(false);
				setSelectSmartViewResult(null);
				await fetchAllSmartViewConfigs();
			});
		},
		[isMounted, tryRunAsync, fetchAllSmartViewConfigs, selectSmartViewResult]
	);

	const handleOnNew = (item) => {
		setHandlingState("new");
		setSelectSmartViewResult(item);
		setModalOpen(true);
	};

	const handleOnEdit = (item) => {
		setHandlingState("edit");
		setSelectSmartViewResult(item);
		setModalOpen(true);
	};

	const handleOnDelete = (item) => {
		setSelectSmartViewResult(item);
		setModalOpen(false);
		createOrUpdateSmartViewConfig(item, true);
	};

	const handleEditorOnCancel = () => {
		setModalOpen(false);
	};

	const handleEditorOnSubmit = (data) => {
		console.debug("Submitting Network Data", data);
		createOrUpdateSmartViewConfig(data);
	};

	return (
		<div>
			<RotateLoader
				css={styles.override}
				size={30}
				margin={30}
				color={"#e6282c"}
				loading={isLoading}
			/>
			{/* <div id="topDivider" className="container is-margin-top"> */}
			<SmartViewConfigEdit
				open={modalOpen}
				onCancel={handleEditorOnCancel}
				onSubmit={handleEditorOnSubmit}
				selectSmartViewResult={selectSmartViewResult}
				smartViewConfig={selectSmartViewResult?.item?.smartViewConfig ?? null}
				existingSmartViewConfigs={smartViewResults}
				isLoading={isLoading}
				templates={templates}
				userProfiles={userProfiles}
			/>
			<div className="container is-fluid is-margin-top">
				<h1 className="subtitle has-text-weight-semibold">Manage Smart View Configurations</h1>
				<SmartViewsTable smartViews={tableData} onNew={null} />
			</div>
			{/* </div> */}
		</div>
	);
};
