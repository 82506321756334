// import { jsx } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { InputFormField, InputFormDropDownField, InputFormMultiButtonSelect, InputFormMultiDropDownSelect } from "./form-items";

const InitialFormData = (id, smartViewConfig) => {
    return {
        smartViewId: {
            value: id
        },
        textsPerMinute: {
            value: "15"
        },
        daysOfWeek: {
            value: ""
        },
        limitPerDay: {
            value: "max"
        },
        templateUuid: {
            value: ""
        },
        userProfiles: {
            value: []
        },
        isEnabled: {
            value: true
        },
        configUsername: {
            value: smartViewConfig?.username ?? null
        }
    };
}

export const SmartViewConfigForm = ({
    onChange,
    smartViewId = null,
    smartViewConfig = null,
    existingSmartViewConfigs = null,
    templates = [],
    userProfiles = []
}) => {
    const [formData, setFormData] = useState(InitialFormData(smartViewId, smartViewConfig));
    const smartViewConfigsRef = useRef(new Set());

    useEffect(() => {
        if (!smartViewConfigsRef.current) {
            return;
        }
        smartViewConfigsRef.current.clear();
        existingSmartViewConfigs?.forEach(({ item }) => {
            smartViewConfigsRef.current.add(item);
        });
    }, [existingSmartViewConfigs]);

    useEffect(() => {
        if (!smartViewConfig) return;
        setFormData(dehydrateFormData(smartViewConfig));
    }, [smartViewConfig]);

    useEffect(() => {
        onChange(hydrateFormData());
    }, [formData]);

    const handleFormChange = (fieldName) => (value) => {
        const updatedFormData = {
            ...formData,
            [fieldName]: {
                value,
            },
        };
        setFormData(updatedFormData);
    };

    const handleCheckBoxChange = () => {
        setFormData({
            ...formData,
            isEnabled: {
                value: !formData.isEnabled.value,
            },
        });
    };

    const dehydrateFormData = (hydrated) => {
        let data = {};
        Object.keys(hydrated).forEach((fieldName) => {
            data[fieldName] = {
                value: hydrated[fieldName],
            };
        });
        return data;
    };

    const hydrateFormData = () => {
        let hydrated = {};
        Object.keys(formData).forEach((fieldName) => {
            console.log("FIELD NAME:", fieldName, formData[fieldName].value);
            hydrated[fieldName] = formData[fieldName].value;
        });
        return hydrated;
    };

    const isUpdateMode = smartViewConfig !== null;
    return (
        <form>
            <div className="panel-block">
                <InputFormField
                    name="smartViewId"
                    formData={formData}
                    onChange={handleFormChange}
                    readonly={true}
                />
            </div>
            {isUpdateMode && (
                <div className="panel-block">
                    <InputFormField
                        name="configUsername"
                        formData={formData}
                        onChange={handleFormChange}
                        readonly={true}
                    />
                </div>)}
            <div className="panel-block">
                <InputFormMultiDropDownSelect
                    name="userProfiles"
                    formData={formData}
                    selectOptions={userProfiles}
                    onChange={handleFormChange}
                />
            </div>
            <div className="panel-block">
                <InputFormField
                    name="textsPerMinute"
                    formData={formData}
                    onChange={handleFormChange}
                // readonly={isUpdateMode}
                />
            </div>
            <div className="panel-block">
                <InputFormMultiButtonSelect
                    name="daysOfWeek"
                    formData={formData}
                    onChange={handleFormChange}
                />
            </div>
            <div className="panel-block">
                <InputFormField
                    name="limitPerDay"
                    formData={formData}
                    onChange={handleFormChange}
                />
            </div>
            <div className="panel-block">
                <InputFormDropDownField
                    name="templateUuid"
                    formData={formData}
                    selectOptions={templates}
                    onChange={handleFormChange}
                />
            </div>
            {formData.isEnabled !== undefined && (
                <div className="panel-block is-flex">
                    <label className="checkbox">
                        <input
                            type="checkbox"
                            checked={formData.isEnabled.value}
                            onChange={handleCheckBoxChange}
                        />
                        <span className="ml-1 is-size-5">Is Enabled</span>
                    </label>
                </div>
            )}
        </form>
    );
};
