import React, { useEffect, useState } from "react";
import { Table } from "../../../components/Table";
import { SmartViewsToolbar } from "./SmartViewsToolbar";

export const SmartViewsTable = ({ smartViews, onNew }) => {
    const [filteredSmartViews, setFilteredSmartViews] = useState(smartViews);
    const [searchTerm, setSearchTerm] = useState("");
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        const onlyStrings = (cel) => typeof cel === "string";

        const filterByTerm = (row) => row && row.filter(onlyStrings)
            .findIndex((cel) => cel && cel.includes(searchTerm)) !== -1;

        const filterRecords = (row) => row && row.filter(onlyStrings)
            .findIndex((cel) => cel && cel.includes("serviceRecord#")) === -1;

        const filteredByShowAll = {
            header: smartViews.header, rows: showAll
                ? smartViews.rows : smartViews.rows.filter(filterRecords)
        };

        if (!searchTerm) {
            setFilteredSmartViews(filteredByShowAll);
            return;
        }

        const result = {
            header: smartViews.header,
            rows: filteredByShowAll.rows.filter(filterByTerm),
        };

        setFilteredSmartViews(result);
    }, [searchTerm, showAll, smartViews]);

    const handleSearch = (term) => {
        setSearchTerm(term);
    };

    const handleShowAll = (checked) => {
        setShowAll(checked);
    };

    if (!filteredSmartViews) return;

    return (
        <>
            <SmartViewsToolbar
                onNew={null}
                onSearch={handleSearch}
                onShowAllChanged={handleShowAll}
                checked={showAll}
            />
            <Table data={filteredSmartViews} pageSize={100} />
        </>
    );
};
