import { css } from "@emotion/react";
import React, { useState } from "react";
import startCase from "lodash.startcase";
import DatePicker from "react-datepicker";
import { voidFn } from "../../../../utils/voidFn";
import { identityFn } from "../../../../utils/identityFn";

const labelStyle = css`
	position: absolute;
	left: 0;
	top: -16px;
	width: 100%;
	font-size: smaller;
`;

const pickerStyle = css`
	margin-right: 0.25rem;
	width: 140px;
`;

const DateInput = React.forwardRef(({ value, onClick, onChange }, ref) => (
	<>
		<button
			className="button is-primary is-full-width"
			defaultValue={value}
			onClick={onClick}
		>
			<span className="icon">
				<i className="fas fa-calendar-alt" />
			</span>
			<span>{value}</span>
		</button>
	</>
));

export const InputFormDateField = ({
	name,
	formData,
	readonly = false,
	onChange = () => voidFn,
	customLabel = undefined,
	formatFunction = identityFn,
}) => {
	const label = customLabel || startCase(name);

	const handleStartChange = (date) => {
		// onChange({ start: date });
		// onChange(name)(formatFunction(event.target.value));
		// console.log(date);
		onChange(name)(formatFunction(date));
	};

	// const handleChange = (event) => {
	//     onChange(name)(formatFunction(event.target.value));
	// };

	return (
		<div className="field is-full-width">
			<div className="control">
				<label className="is-size-6 has-text-grey">
					{label}
					<div css={pickerStyle}>
						<DatePicker
							selected={
								formData[name]?.value ? new Date(formData[name]?.value) : ""
							}
							onChange={handleStartChange}
							selectsStart
							startDate={
								formData[name]?.value ? new Date(formData[name]?.value) : ""
							}
							// customInput={<DateInput />}
						/>
					</div>
				</label>
			</div>
		</div>
	);
};
