import React from "react";
import startCase from "lodash.startcase";
import { voidFn } from "../../../../utils/voidFn";
import { identityFn } from "../../../../utils/identityFn";

export const InputFormField = ({
	name,
	formData,
	readonly = false,
	onChange = () => voidFn,
	customLabel = undefined,
	formatFunction = identityFn,
}) => {
	const label = customLabel || startCase(name);

	const handleChange = (event) => {
		onChange(name)(formatFunction(event.target.value));
	};

	return (
		<div className="field is-full-width">
			<div className="control">
				<label className="is-size-6 has-text-grey">
					{label}
					<input
						className="input is-medium"
						type="text"
						placeholder={label}
						value={formData[name]?.value}
						onChange={handleChange}
						disabled={readonly}
					/>
				</label>
				{formData[name]?.meta?.error && (
					<small className="has-text-danger">{formData[name].meta.error}</small>
				)}
			</div>
		</div>
	);
};
