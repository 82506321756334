import React from "react";

export const IconButton = ({ onClick, title, iconName, styles = {}, classOptions = 'is-primary is-full-width is-small' }) => (
	<button
		className={`button ${classOptions}`}
		onClick={onClick}
		title={title}
	>
		<span className="icon">
			<i className={`fas ${iconName} is-primary`} />
		</span>
	</button>
);
