import React from "react";

const DefaultActions = [
	{
		title: "Cancel",
		icon: null,
		id: "cancel",
		isDefault: false,
	},
	{
		title: "Submit",
		icon: "fa-check",
		id: "submit",
		isDefault: true,
	},
];

export const Modal = ({
	actionsDisabled,
	isVisible,
	title,
	onClose,
	onAction,
	children,
	actions = DefaultActions,
}) => {
	const handleAction = (actionId) => {
		onAction(actionId);
	};

	const handleOnClose = (e) => {
		e.preventDefault();
		onClose(e);
	};

	return (
		<div className={`modal ${isVisible ? "is-active" : ""}`}>
			<div className="modal-background" onClick={handleOnClose} />
			<div className="modal-card">
				<header className="modal-card-head">
					<p className={"modal-card-title is-marginless has-text-centered"}>
						{title}
					</p>
					<button className="delete" onClick={handleOnClose} />
				</header>
				<section className="modal-card-body">{children}</section>
				<footer className="modal-card-foot">
					{actions.map((action) => (
						<button
							key={action.id}
							className={`button ${action.isDefault ? "is-primary" : ""}`}
							onClick={() => handleAction(action.id)}
							disabled={actionsDisabled}
						>
							{action.icon && (
								<span className="icon is-small">
									<i className={`fas ${action.icon}`} />
								</span>
							)}
							<span>{action.title}</span>
						</button>
					))}
				</footer>
			</div>
		</div>
	);
};
