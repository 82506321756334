import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home, GoogleLogin, Login, Profile, Schedule } from "../views";

const Router = () => (
	<div>
		<Routes>
			<Route exact path="/" element={<Home />} />
			<Route exact path="/login" element={<Login />} />
			<Route exact path="/googleLogin" element={<GoogleLogin />} />
			<Route exact path="/schedule" element={<Schedule />} />
			<Route exact path="/profile" element={<Profile />} />
			{/* TOOD: redirect below * to / */}
			{/* <Route path="*" element={<Home />} /> */}
		</Routes>
	</div>
);
export default Router;
