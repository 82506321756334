import React, { useEffect, useState, useCallback } from "react";
import RotateLoader from "react-spinners/RotateLoader";
import { Box, Button, Form, Message } from 'react-bulma-components';
import styles from "../../styles";
import services from "../../services";
const { Control, Field, Label, Input } = Form;

export default () => {
    const [isFirstPageLoad, setIsFirstPageLoad] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const [apiKeyText, setApiKeyText] = useState("");

    const updateProfile = async () => {
        const result = await services.api.getProfile();
        setUserProfile(result);
        setApiKeyText(result.apiKey ?? "");
    }

    // manage api rendering on the fly
    useEffect(() => {
        let active = true;
        load();
        return () => {
            active = false;
        };

        async function load() {
            if (!isFirstPageLoad) {
                return;
            }
            setIsLoading(true);
            setIsFirstPageLoad(false);
            await updateProfile();
            setIsLoading(false);
            if (!active) {
                return;
            }
        }
    }, [userProfile]);

    return (
        <div className="container is-fluid is-margin-top">
            <RotateLoader
                css={styles.override}
                size={30}
                margin={30}
                color={"#e6282c"}
                loading={isLoading}
            />
            <Box>
                <Field>
                    <Label>
                        API Key:
                    </Label>
                    <Control>
                        <Input className="is-primary2-border"
                            placeholder="Enter Api Key Here"
                            type="text"
                            value={apiKeyText}
                            onChange={(e) => { setApiKeyText(e.target.value) }}
                        />
                    </Control>
                    <Label>
                        Voip Phone Number Association:
                    </Label>
                    <Control>
                        <Input className="is-primary2-border"
                            placeholder="(XXX)-XXX-XXXX"
                            type="text"
                            value={userProfile?.voipPhoneNumber}
                            disabled
                        />
                    </Control>
                    {/* <Help>
                        Help text for this field
                    </Help> */}
                </Field>
                <Field>
                    {/* <Label>
                        With Button
                    </Label>
                    <Field kind="addons">
                        <Control>
                            <Button disabled>
                                +1
                            </Button>
                        </Control>
                        <Control fullwidth>
                            <Input placeholder="555-555-555" type="tel" />
                        </Control>
                    </Field> */}
                    <Field align="center" kind="group">
                        <Control>
                            <Button color="primary" onClick={async () => {
                                setIsLoading(true);
                                await services.api.updateProfile({
                                    apiKey: apiKeyText
                                });
                                await updateProfile();
                                setIsLoading(false);
                            }}>Save</Button>
                        </Control>
                        {/* <Control>
                            <Button color="info">
                                Cancel
                            </Button>
                        </Control>
                        <Control>
                            <Button color="link">
                                Reset
                            </Button>
                        </Control> */}
                    </Field>
                </Field>
            </Box>
        </div>);
}