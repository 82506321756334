import fakeData from '../../../test/data/users.json'
import React, { useEffect, useState } from "react";
import { Table, Pagination, Toggle, TagPicker } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import services from '../../../services';
const { Column, HeaderCell, Cell } = Table;

const defaultColumnData = [
    <Column width={300} fixed="left">
        <HeaderCell>Action</HeaderCell>
        <Cell>
            {rowData => {
                async function handleAction(action) {
                    // alert(`id:${rowData.id}`);
                    console.log("HELLO WORLD", action)
                    if (action === 'info') {
                        // const fullItem = rowData?.fullItem;
                        // const contact = fullItem?.contacts[0];
                        // await services.api.scheduleSms([]);
                        return;
                    }
                    if (action === 'schedule') {
                        // TODO: 
                        return;
                    }
                }
                return (
                    <span>
                        <a className='is-primary2-button' onClick={async () => { handleAction("Create") }}> Info </a>
                        | <a className='is-primary2-button' onClick={async () => { handleAction("Edit") }}> Schedule</a>
                        | <a className='is-primary2-button' onClick={async () => { handleAction("Edit") }}> Delete</a>
                    </span>
                );
            }}
        </Cell>
    </Column>,
    <Column width={100} align="center" resizable>
        <HeaderCell>Id</HeaderCell>
        <Cell dataKey="id" />
    </Column>,
    <Column width={200} resizable>
        <HeaderCell>Display Name</HeaderCell>
        <Cell dataKey="name" />
    </Column>,
    <Column width={200} resizable>
        <HeaderCell>Is Shared</HeaderCell>
        <Cell dataKey="is_shared" />
    </Column>,
    <Column width={100} resizable>
        <HeaderCell>Type</HeaderCell>
        <Cell dataKey="type" />
    </Column>,
    <Column width={200} resizable>
        <HeaderCell>query</HeaderCell>
        <Cell dataKey="query" />
    </Column>
];

export default ({ tableData, columnDataOverride }) => {
    const [loading, setLoading] = React.useState(false);
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);

    const formatDefaultData = (tableData) => {
        return tableData ?? fakeData;
    }

    const setColumnData = (columnData = defaultColumnData) => {
        // TODO: more here
        return columnData;
    }

    const handleChangeLimit = dataKey => {
        setPage(1);
        setLimit(dataKey);
    };

    return (
        <div>
            <Table
                height={400}
                data={formatDefaultData(tableData)}
                onRowClick={data => {
                    // console.log(data);
                }}
            >
                {setColumnData(columnDataOverride)}
            </Table>
            <div style={{ padding: 20 }}>
                <Pagination
                    prev
                    next
                    first
                    last
                    ellipsis
                    boundaryLinks
                    maxButtons={5}
                    size="xs"
                    layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                    total={tableData.length}
                    limitOptions={[10, 20]}
                    limit={limit}
                    activePage={page}
                    onChangePage={setPage}
                    onChangeLimit={handleChangeLimit}
                />
            </div>
        </div>
    );
}