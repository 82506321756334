import React, { useState } from "react";
import LoginHooks from './LoginHooks';
// import LogoutHooks from './LogoutHooks';

export default () => {
    return (
        <div className="hero">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <div className="column is-8 is-offset-2">
                        <LoginHooks />
                        {/* <p />
                        <LogoutHooks /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};
