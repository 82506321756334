import React from "react";
import Helmet from "react-helmet";
import Site from "./Site";
import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";
import Router from "./Router";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default () => (
	<Site>
		<Helmet
			title="principles rei group web tool"
			meta={[
				{ name: "description", content: "principlesreigroup project site" },
				{ name: "keywords", content: "principlesreigroup" },
			]}
			script={[
				{ src: "https://use.fontawesome.com/releases/v5.4.1/js/all.js" },
			]}
			link={[
				{
					rel: "stylesheet",
					href: "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css",
				},
			]}
		/>
		<Header />
		<Content>
			<ToastContainer
				position="top-center"
				autoClose={3000}
				closeOnClick
				pauseOnHover
				transition={Slide}
			/>
			<Router />
		</Content>
		<Footer />
	</Site>
);
