import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import startCase from "lodash.startcase";
import { voidFn } from "../../../../utils/voidFn";
import { identityFn } from "../../../../utils/identityFn";

export const InputFormMultiDropDownSelect = ({
    name,
    formData,
    selectOptions = [],
    onChange = () => voidFn,
    customLabel = undefined,
    formatFunction = identityFn,
}) => {
    const label = customLabel || startCase(name);
    const [defaultValues, setDefaultValues] = useState([]);

    useEffect(() => {
        setDefaultValues(formData[name]?.value?.length > 0 ? formData[name].value.map(item => ({
            label: item.username,
            value: item.username
        })) : []);
    }, [formData]);

    const handleChange = (event) => {
        onChange(name)(formatFunction(event.map(item => ({
            username: item.value
        }))));
    };

    return (
        <div className="field is-full-width">
            <label className="is-size-6 has-text-grey">
                {label}:
            </label>
            <div className="control">
                <Select
                    isMulti
                    // defaultValue={defaultValues}
                    value={defaultValues}
                    onChange={handleChange}
                    options={selectOptions.map(item => ({
                        label: item.username,
                        value: item.username
                    }))}
                />
            </div>
        </div>
    );
};
