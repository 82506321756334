import axios from "axios";
import auth from "./auth";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const login = async (username, password) => {
	const response = await axios.post(`${API_ENDPOINT}/login`, {
		username,
		password,
	});
	if (response.status !== 200) {
		return null;
	}
	return response.data.authToken;
};

const verifyAccount = async (username, accessCode, password) => {
	const response = await axios.post(`${API_ENDPOINT}/verify`, {
		username,
		accessCode,
		password,
	});
	if (response.status !== 200) {
		return null;
	}
	return response.data;
};

const sendAccessCode = async (username) => {
	const response = await axios.post(`${API_ENDPOINT}/sendAccessCode`, {
		username,
	});
	if (response.status !== 200) {
		return null;
	}
	return response.data;
};

const logout = async (token) => {
	const response = await axios.post(`${API_ENDPOINT}/logout`, {
		token,
	});
	if (response.status !== 200) {
		return null;
	}
	return response.data;
};

const validateFile = async (fileName, fileData, type = "PROVIDER") => {
	const getPresignedUrlResponse = await axios.get(
		`${API_ENDPOINT}/getPresignedUrl`,
		{
			headers: {
				Authorization: `${auth.getToken()}`,
			},
			params: {
				objectName: fileName,
			},
		}
	);
	if (getPresignedUrlResponse.status !== 200) {
		return null;
	}
	const presignedUrl = getPresignedUrlResponse.data.url;

	const putObjectResponse = await axios.put(presignedUrl, fileData, {
		headers: {
			"Content-Type": "application/octet-stream",
		},
	});
	if (putObjectResponse.status !== 200) {
		return null;
	}

	const validateFileResponse = await axios.post(
		`${API_ENDPOINT}/validateFile`,
		{
			type: type,
			fileName: fileName,
		},
		{
			headers: {
				Authorization: `${await auth.getToken()}`,
			},
		}
	);
	if (validateFileResponse.status !== 200) {
		return null;
	}
	return validateFileResponse.data;
};

const getSmartViews = async () => {
	try {
		const response = await axios.get(
			`${API_ENDPOINT}/getSmartViews`,
			{
				headers: {
					Authorization: auth.getGoogleAuthToken()
				},
				params: {},
			}
		);
		console.log("RESPONSE DATA:", response.data)
		return response.data;
	} catch (e) {
		console.log("FATAL GET SMART VIEWS ERROR:", e)
		return [];
	}
};

const getSmartViewResultsById = async (id) => {
	try {
		const response = await axios.get(
			`${API_ENDPOINT}/getSmartViewResultsById`,
			{
				headers: {
					Authorization: auth.getGoogleAuthToken()
				},
				params: {
					id
				},
			}
		);
		console.log("RESPONSE DATA:", response.data)
		return response.data.data;
	} catch (e) {
		console.log("FATAL GET SMART VIEWS ERROR:", e)
		return [];
	}
};

const scheduleSms = async (schedules) => {
	try {
		const response = await axios.post(`${API_ENDPOINT}/scheduleSms`, { schedules }, {
			headers: {
				Authorization: auth.getGoogleAuthToken()
			}
		});
		console.log("RESPONSE DATA:", response.data)
		return response.data.data;
	} catch (e) {
		console.log("FATAL GET SMART VIEWS ERROR:", e)
		throw Error("UNABLE TO SUBMIT SMS");
	}
};

const getScheduledSmsEvents = async (month) => {
	try {
		const response = await axios.get(
			`${API_ENDPOINT}/getScheduledSmsEvents`,
			{
				headers: {
					Authorization: auth.getGoogleAuthToken()
				},
				params: {
					month
				},
			}
		);
		// console.log("RESPONSE DATA:", response.data)
		return response.data;
	} catch (e) {
		console.log("FATAL GET SMART VIEWS ERROR:", e)
		return [];
	}
}

const getProfile = async () => {
	try {
		const response = await axios.get(
			`${API_ENDPOINT}/getProfile`,
			{
				headers: {
					Authorization: auth.getGoogleAuthToken()
				},
				params: {
				},
			}
		);
		return response.data;
	} catch (e) {
		console.log("FATAL", e)
		return {};
	}
}

const updateProfile = async (profile) => {
	try {
		const response = await axios.put(`${API_ENDPOINT}/updateProfile`, {
			apiKey: profile.apiKey
		}, {
			headers: {
				Authorization: auth.getGoogleAuthToken()
			}
		});
		return response.data;
	} catch (e) {
		console.log(e);
		throw Error("UNABLE TO UPDATE PROFILE");
	}
};

// functional service calls, NOTE: we could move this out //

/**
 * @param {*} schedules 
 * @param {*} interval {0 - 10 in hours}
 * @param {*} templates 
 */
const scheduleSmsRandomIntervalsAndTemplates = async (schedules, interval, templates) => {

}

const getAllTemplates = async () => {
	try {
		const response = await axios.get(
			`${API_ENDPOINT}/getAllTemplates`,
			{
				headers: {
					Authorization: auth.getGoogleAuthToken()
				},
				params: {
				},
			}
		);
		return response.data;
	} catch (e) {
		console.log("FATAL", e)
		return {};
	}
}

const getSmartViewConfig = async (id) => {
	try {
		const response = await axios.get(
			`${API_ENDPOINT}/getSmartViewConfig`,
			{
				headers: {
					Authorization: auth.getGoogleAuthToken()
				},
				params: {
					id
				},
			}
		);
		return response.data;
	} catch (e) {
		console.log("FATAL", e)
		return {};
	}
}

const updateSmartViewConfig = async (data) => {
	try {
		const response = await axios.put(`${API_ENDPOINT}/updateSmartViewConfig`, {
			...data
		}, {
			headers: {
				Authorization: auth.getGoogleAuthToken()
			}
		});
		return response.data;
	} catch (e) {
		console.log(e);
		throw Error("UNABLE TO UPDATE CONFIG");
	}
}

const createSmartViewConfig = async (data) => {
	try {
		const response = await axios.post(`${API_ENDPOINT}/createSmartViewConfig`, { ...data }, {
			headers: {
				Authorization: auth.getGoogleAuthToken()
			}
		});
		console.log("RESPONSE DATA:", response.data)
		return response.data;
	} catch (e) {
		console.log("FATAL GET SMART VIEWS ERROR:", e)
		throw Error("UNABLE TO SUBMIT SMS");
	}
}

const deleteSmartViewConfig = async (id) => {
	try {
		const response = await axios.delete(`${API_ENDPOINT}/deleteSmartViewConfig`, {
			headers: {
				Authorization: auth.getGoogleAuthToken()
			},
			params: {
				id
			}
		});
		console.log("RESPONSE DATA:", response.data)
		return response.data;
	} catch (e) {
		console.log("FATAL GET SMART VIEWS ERROR:", e)
		throw Error("UNABLE TO SUBMIT SMS");
	}
}

const getUserProfiles = async () => {
	try {
		const response = await axios.get(
			`${API_ENDPOINT}/getUserProfiles`,
			{
				headers: {
					Authorization: auth.getGoogleAuthToken()
				},
				params: {
				},
			}
		);
		return response.data;
	} catch (e) {
		console.log("FATAL", e)
		return {};
	}
}

export default {
	login,
	logout,
	sendAccessCode,
	verifyAccount,
	validateFile,
	getSmartViews,
	getSmartViewResultsById,
	scheduleSms,
	getScheduledSmsEvents,
	getProfile,
	updateProfile,
	scheduleSmsRandomIntervalsAndTemplates,
	getAllTemplates,
	getSmartViewConfig,
	updateSmartViewConfig,
	createSmartViewConfig,
	deleteSmartViewConfig,
	getUserProfiles
};
